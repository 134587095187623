import React, { Component } from 'react';

import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import ScrollAnimation from 'react-animate-on-scroll';

// import Bg from 'src/assets/images/bg.mp4';
import Overlay from 'src/assets/images/overlay.svg';
export default class Teaser extends Component {
  state = {};

  componentDidMount() {
    this.setState({
      topElement: document.getElementById('entryTop'),
      botElement: document.getElementById('entryBottom'),
      animFinish: false,
      lastWidth: window.innerWidth,
    });
    window.addEventListener('scroll', this.sticky);
    window.addEventListener('resize', this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.sticky);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({
      topElement: document.getElementById('entryTop'),
      botElement: document.getElementById('entryBottom'),
    });
    if (window.innerWidth != this.state.lastWidth) {
      this.state.topElement.style.position = 'absolute';
      this.state.topElement.style.top =
        0 - this.state.topElement.clientHeight + 'px';
    }
  };

  sticky = () => {
    if (
      this.state.topElement.getBoundingClientRect().bottom + 24 >=
        this.state.botElement.getBoundingClientRect().top &&
      this.state.animFinish == false
    ) {
      this.state.topElement.style.position = 'absolute';
      this.state.topElement.style.top =
        0 - this.state.topElement.clientHeight + 'px';
      this.setState({ animFinish: true });
    }
  };

  render() {
    return (
      //   <div class="teaserWrapper">
      <Root className="teaserScreen">
        <div className="backgroundVideo">
          <img src={Overlay} />
          <video
            playsInline
            autoPlay
            muted
            // controls
            // loop
            // className="backgroundVideo"
            src={
              'https://player.vimeo.com/external/348221055.hd.mp4?s=2bff00359a1bdb1228d2bcfd1155946c316876de&profile_id=175'
            }
            type="video/mp4"
          />
        </div>
        <ScrollMobile>
          <span>Scroll</span>
        </ScrollMobile>

        <EntryWrap className="entryTextWrapper">
          <div className="mobileEntry">
            <h1 className="entryText" id="entryTop">
              <ScrollAnimation
                animateIn="slideInUp"
                delay={100}
                animateOnce={true}
              >
                We bring <span className="clarity">clarity</span>
                <br /> to your financial landscape.
              </ScrollAnimation>
            </h1>
            <div className="entrySubtext" id="entryBottom">
              <p>
                As experienced accounting and tax professionals our focus is on
                helping businesses understand their financial landscape and
                leveraging our expertise to help drive businesses forward.
              </p>
              <button
                className="getInTouch"
                type="button"
                onClick={() => {
                  this.props.scrollFunc(5);
                }}
              >
                <span>get in touch</span>
              </button>
            </div>
          </div>
          <ScrollAnimation
            className="desktopEntry"
            animateIn="slideInUp"
            delay="100"
            animateOnce="true"
          >
            <h1 className="entryText">
              We bring <span className="clarity">clarity</span>
              <br /> to your financial landscape.
            </h1>

            <div className="entrySubtext">
              <p>
                As experienced accounting and tax professionals our focus is on
                helping businesses understand their financial landscape and
                leveraging our expertise to help drive businesses forward.
              </p>
              <button
                className="getInTouch"
                type="button"
                onClick={() => {
                  this.props.scrollFunc(5);
                }}
              >
                <span>get in touch</span>
              </button>
            </div>
          </ScrollAnimation>
        </EntryWrap>
      </Root>
    );
  }
}
const ScrollMobile = styled.div`
  transform: rotate(270deg);
  font-family: Montserrat;
  font-size: 14px;
  text-transform: uppercase;
  position: absolute;
  top: 67vh;
  right: 0px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  @media (min-width: ${media.sm}) {
    display: none;
  }

  ::before {
    content: '';
    transform: rotate(270deg);
    width: 1px;
    height: 100px;
    background-image: linear-gradient(179deg, #af7a27, #ecc45d 30%, #bb943e);
    position: absolute;
    left: -60px;
  }
`;
const Root = styled.div`
  /* height: 200vh; */
  overflow: hidden;
  background:#333333;
  position: relative;
  z-index: 4;
  width: auto;
  padding-top: 100px;
  margin-top: 0px;
  @media(min-width: ${media.sm}){
    margin-top: -100px;
  }
  /* background-blend-mode: multiply; */
  /* background-image: url(${require('src/assets/images/teaserCenter.jpg')}); */
  /* background-size: auto 100vh;
  background-repeat: no-repeat; */
  /* Mobile */
  padding: 0 20px;
  /* background-position: center 0; */
  padding-bottom: 200px;
  /* margin-top: 100px; */
  .backgroundVideo{
    position: absolute;
    top: -10%;
    left: 0;
    height: 100vh;
    /* padding-top: 75px; */
    transform: scale(3);
    width: 100%;
    
    @media (min-width: ${media.sm}){
      left: 30%;
      top: 0;
      /* width: 100%; */
      overflow:hidden;
      transform: translateX(0);
    }
    /* transform: translateX(-100%); */
    img{
      position: absolute;
      overflow:hidden;
      top: -1px;
      left: -1px;
      height: 100%;
      width: 100%;
      /* padding-top: 75px; */
      transform: scale(1.05);

    }
    video{
      /* object-fit: cover; */
      /* position:absolute; */
      overflow:hidden;
      top: 0;
      left: 0;
      /* padding-top: 75px; */
      height: 100%;
      width: 100%;
      /* transform: translateX(-20%); */
      /* height: 100%; */
    }
    
    
  }
  @media (min-width: ${media.sm}) {
    /* background-image: url(${require('src/assets/images/teaser.jpg')}); */
    /* background-image: url(${require('src/assets/images/bg.mp4')}); */
    padding-left: 35px;
    padding-right: 35px;

    margin-bottom: 0;
    /* background-size: cover; */
    height: 100vh;
    /* background-position-x: -250px; */
  }
  @media (min-width: ${media.md}) {
    padding: 0 50px;
    /* background-position-x: 50px; */
  }
  @media (min-width: ${media.lg}) {
    padding: 0 100px;
    /* background-position-x: 150px; */
  }
`;

const EntryWrap = styled.div`
  position: relative;
  margin-top: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${media.sm}) {
    /* margin-top: 9%; */
    display: block;
    position: absolute;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
  }
  .mobileEntry {
    display: block;
    /* padding-bottom: 30vh; */
    @media (min-width: ${media.sm}) {
      display: none;
    }
    .entryText {
      position: fixed;
      top: 20%;
      font-family: Montserrat;
      font-size: 32px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: white;
      /* transition: all 0.2s; */
      .clarity {
        font-weight: 500;
        color: #c5993e;
      }
    }
    .entrySubtext {
      margin-top: 24px;
      margin-left: 0px;
      /* position: absolute; */
      top: 0;
      max-width: 100%;
      p {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.78;
        letter-spacing: normal;
        color: white;
      }
      button.getInTouch {
        cursor: pointer;
        margin-top: 24px;
        padding: 13px 40px;
        background: #c5993e;
        width: fit-content;

        span {
          color: white;
          text-decoration: none;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
    }
  }
  .desktopEntry {
    display: none;
    @media (min-width: ${media.sm}) {
      display: block;
    }
    .entryText {
      font-family: Montserrat;
      font-size: 32px;
      /* opacity: 0; */
      /* animation: slideUp 1s forwards; */
      /* animation-delay: 500ms; */
      /* margin-top: 100vh; */
      @media (min-width: ${media.sm}) {
        font-size: 30px;
        /* padding: 0 35px; */
        padding-top: 0;
      }
      @media (min-width: ${media.md}) {
        font-size: 40px;
        /* padding: 0 50px; */
      }
      @media (min-width: ${media.lg}) {
        font-size: 48px;
      }
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: white;

      .clarity {
        font-weight: 500;
        color: #c5993e;
      }
    }
    .entrySubtext {
      margin-top: 24px;
      /* opacity: 0; */
      /* animation: slideUp 1s forwards; */
      /* animation-delay: 600ms; */
      margin-left: 0px;

      max-width: 100%;
      @media (min-width: ${media.sm}) {
        margin-left: 50px;
        max-width: 50%;
      }
      p {
        font-family: Montserrat;
        font-size: 16px;
        @media (min-width: ${media.sm}) {
          font-size: 16px;
        }
        @media (min-width: ${media.md}) {
          font-size: 17px;
        }
        @media (min-width: ${media.lg}) {
          font-size: 18px;
        }
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.78;
        letter-spacing: normal;
        color: white;
      }
      button.getInTouch {
        /* animation: slideUp 1s forwards;
      animation-delay: 700ms;
      opacity: 0; */
        cursor: pointer;
        margin-top: 24px;
        padding: 13px 40px;

        /* border: 1px solid;
      border-image: linear-gradient(
          90deg,
          rgb(175, 122, 39),
          rgb(231, 192, 90),
          rgb(175, 122, 39)
        )
        1; */
        background: #c5993e;
        width: fit-content;

        span {
          color: white;
          text-decoration: none;
          font-family: Montserrat;
          font-size: 14px;
          @media (min-width: ${media.sm}) {
            font-size: 12px;
          }
          @media (min-width: ${media.md}) {
            font-size: 13px;
          }
          @media (min-width: ${media.lg}) {
            font-size: 14px;
          }
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          /* background: linear-gradient(
          90deg,
          rgb(175, 122, 39),
          rgb(231, 192, 90),
          rgb(175, 122, 39)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
        }
      }
    }
  }
  /* align-items: center; */
`;

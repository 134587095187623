import React, { Component } from 'react';

import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
export default class InfoBox extends Component {
  state = {
    active: 1,
    topOffset: 20,
    prev: 1,
  };
  componentDidMount() {
    // const text2 = document.querySelector('#taxText');
    // const text3 = document.querySelector('#advisoryText');
    // const text4 = document.querySelector('#cfoText');
    // text2.style.transform = 'translateY(300%)';
    // text3.style.transform = 'translateY(300%)';
    // text4.style.transform = 'translateY(300%)';
    // text2.style.animation = 'fadeInDown 1s';
  }
  change = i => {
    this.setState({ active: i });
    var activeP = document.querySelector('#descBox2 > .active');
    // activeP.style.animation = 'fadeInUp 1s';
    const text1 = document.querySelector('#businessText');
    const text2 = document.querySelector('#individualText');
    const text3 = document.querySelector('#nonText');
    const text4 = document.querySelector('#realText');

    if (i == 1) {
      //top
      //bottom 2,3,4

      text2.style.animation = 'none';
      text3.style.animation = 'none';
      text4.style.animation = 'none';

      if (i <= this.state.prev) {
        text1.style.animation = 'slideDown 1s';
      } else {
        text1.style.animation = 'slideUp 1s';
      }
    } else if (i == 2) {
      //top 1
      //bottom 3,4
      text1.style.animation = 'none';
      text3.style.animation = 'none';
      text4.style.animation = 'none';
      if (i <= this.state.prev) {
        text2.style.animation = 'slideDown 1s';
      } else {
        text2.style.animation = 'slideUp 1s';
      }
    } else if (i == 3) {
      //top 1, 2
      //bottom 4
      text1.style.animation = 'none';
      text2.style.animation = 'none';
      text4.style.animation = 'none';
      if (i <= this.state.prev) {
        text3.style.animation = 'slideDown 1s';
      } else {
        text3.style.animation = 'slideUp 1s';
      }
    } else if (i == 4) {
      //top 1, 2, 3
      //bottom 0
      text1.style.animation = 'none';
      text2.style.animation = 'none';
      text3.style.animation = 'none';
      if (i <= this.state.prev) {
        text4.style.animation = 'slideDown 1s';
      } else {
        text4.style.animation = 'slideUp 1s';
      }
    }
    this.setState({ prev: i });
  };
  render() {
    return (
      //   <div class="teaserWrapper">
      <Root>
        <Topic>
          <div
            className="slider"
            style={{ top: this.state.topOffset + (this.state.active - 1) * 60 }}
          />
          <a
            onClick={() => {
              this.change(1);
            }}
            className={this.state.active == 1 ? 'active' : ''}
          >
            Businesses
          </a>
          <a
            onClick={() => {
              this.change(2);
            }}
            className={this.state.active == 2 ? 'active' : ''}
          >
            Individuals
          </a>
          <a
            onClick={() => {
              this.change(3);
            }}
            className={this.state.active == 3 ? 'active' : ''}
          >
            Non-Residents
          </a>
          <a
            onClick={() => {
              this.change(4);
            }}
            className={this.state.active == 4 ? 'active' : ''}
          >
            Real Estate
          </a>
        </Topic>
        <Border />
        <Description id="descBox2">
          <p
            id="businessText"
            className={this.state.active == 1 ? 'active' : ''}
          >
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/corporations/corporation-income-tax-return/when-file-your-corporation-income-tax-return.html"
            >
              Corporate tax filing deadline
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/gst-account/file-a-gst-hst-return.html"
            >
              Filing a GST return
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/e-services/e-services-businesses/payroll-deductions-online-calculator.html"
            >
              CRA payroll deductions calculator
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/e-services/payment-save-time-pay-online.html"
            >
              Making a tax payment
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/compliance/industry-campaign-approach.html"
            >
              CRA’s industry campaign approach
            </a>
          </p>
          <p
            id="individualText"
            className={this.state.active == 2 ? 'active' : ''}
          >
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/important-dates-individuals.html"
            >
              Individual tax filing deadline
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/e-services/payment-save-time-pay-online.html"
            >
              Making a tax payment
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/making-payments-individuals/paying-your-income-tax-instalments.html"
            >
              Paying tax installments
            </a>
            <a
              target="_blank"
              href="https://www.fin.gc.ca/treaties-conventions/in_force--eng.asp"
            >
              Tax treaties between Canada and other countries
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/federal-government-budgets/budget-2016-growing-middle-class/common-reporting-standard.html"
            >
              Common Reporting Standard
            </a>
          </p>
          <p id="nonText" className={this.state.active == 3 ? 'active' : ''}>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/individuals-leaving-entering-canada-non-residents/non-residents-canada.html"
            >
              Non-resident tax filing
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2062.html"
            >
              Non-resident sale of Taxable Canadian Property – Clearance
              Certificate
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/nr73.html"
            >
              NR73 Determination of Residency Status (leaving Canada)
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/information-been-moved/determining-your-residency-status.html"
            >
              Determination of (tax) residency status
            </a>
          </p>
          <p id="realText" className={this.state.active == 4 ? 'active' : ''}>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2091ind.html"
            >
              T2091 Designation of a Property as Principal Residence
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/compliance/real-estate-sector/effects-buying-real-estate-sell-for-profit.html"
            >
              Tax effects of buying and selling real estate
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/personal-income/line-127-capital-gains/principal-residence-other-real-estate/changes-use.html"
            >
              Tax effects on change in use (e.g. principal residence to rental
              and vice versa)
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/gst-hst-home-construction/gst-hst-new-residential-rental-property-rebate.html"
            >
              GST/HST new residential rental property rebate
            </a>
          </p>
        </Description>
      </Root>
      //   </div>
    );
  }
}

const Root = styled.div`
  /* width: 70%; */
  height: 400px;
  background-color: #f7f7f7;

  display: flex;
`;
const Topic = styled.div`
  min-width: 30%;
  position: relative;
  padding: 20px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  a {
    opacity: 0.8;
    font-family: Montserrat;
    font-size: 16px;
    @media (min-width: ${media.sm}) {
      font-size: 14px;
    }
    @media (min-width: ${media.md}) {
      font-size: 15px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 16px;
    }
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #333333;
    height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
  }
  a.active {
    color: #c5993e;
    transition: 0.5s;
  }
  .slider {
    width: 8px;
    height: 60px;
    background-color: #c5993e;
    position: absolute;
    left: 0;
    transition: 0.5s;
  }
`;
const Border = styled.div`
  width: 24px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.08);
`;
const Description = styled.div`
  padding: 20px;
  width: 100%;
  position: relative;
  @media (min-width: ${media.sm}) {
    padding: 25px;
    padding-top: 30px;
  }
  @media (min-width: ${media.md}) {
    padding: 25px;
    padding-top: 35px;
  }

  p {
    /* display: none; */
    /* position: absolute; */
    font-family: Montserrat;
    font-size: 16px;
    padding-right: 20px;
    opacity: 0.8;
    @media (min-width: ${media.sm}) {
      font-size: 13px;
      padding-right: 25px;
    }
    @media (min-width: ${media.md}) {
      font-size: 14px;
    }

    @media (min-width: ${media.lg}) {
      font-size: 15px;
    }
    font-weight: normal;
    /* width: 86%; */

    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: white;
    /* opacity: 0; */
    /* transform: translateY(100%); */
    position: absolute;
    /* opacity: 0; */
    /* max-height: 0; */
    display: none;
    /* animation: fadeInUp 1s; */
    /* transition: transform 0.5s; */
    /* transition: transform 1s, all 0.5s; */
    /* transition: opacity 0.1s; */
    /* max-height: 0; */
  }
  p.active {
    /* display: block; */
    /* opacity: 1; */
    /* max-height: 100%; */
    /* transform: translateY(0) !important; */
    display: block;
    /* animation: fadeIndown 1s infinite; */
    /* visibility: visible; */
  }
  a {
    margin-left: 16px;
    position: relative;
    font-family: Montserrat;
    font-size: 13px;
    @media (min-width: ${media.sm}) {
      font-size: 13px;
    }
    @media (min-width: ${media.md}) {
      font-size: 14px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 15px;
    }
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #404142;
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    align-items: center;
    margin-top: 12px;
  }
  a::before {
    content: '';
    top: 12px;
    @media (min-width: ${media.sm}) {
      top: 10px;
    }
    @media (min-width: ${media.md}) {
      top: 11px;
    }
    @media (min-width: ${media.lg}) {
      top: 12px;
    }
    position: absolute;
    left: -10px;
    width: 4px;
    height: 4px;
    background-color: #b48a33;
    border-radius: 2px;
    margin-right: 12px;
  }
  @keyframes slideUp {
    0% {
      display: none;
      opacity: 0;
      transform: translateY(60px);
    }
    1% {
      display: block;
      opacity: 0;
      transform: translateY(60px);
    }
    100% {
      /* opacity: 1; */
      transform: translateY(0px);
    }
  }
  @keyframes slideDown {
    0% {
      display: none;
      opacity: 0;
      transform: translateY(-60px);
    }
    1% {
      display: block;
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      /* opacity: 1; */
      transform: translateY(0px);
    }
  }
`;

import React, { Component } from 'react';
import Headroom from 'react-headroom';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import logoImg from './../../assets/images/logo.svg';

export default class DesktopMenu extends Component {
  render() {
    return (
      <Headroom
        style={{ zIndex: 3 }}
        className={this.props.mobileActive ? 'headerFix' : ''}
      >
        <Root>
          <Logo
            src={logoImg}
            className="logo"
            onClick={() => {
              this.props.scrollFunc(0);
            }}
          />
          <Content className="content">
            <a
              onClick={() => {
                this.props.scrollFunc(1);
              }}
            >
              testimonials
            </a>
            <a
              onClick={() => {
                this.props.scrollFunc(2);
              }}
            >
              who we are
            </a>
            <a
              onClick={() => {
                this.props.scrollFunc(3);
              }}
            >
              our services
            </a>
            <a
              onClick={() => {
                this.props.scrollFunc(4);
              }}
            >
              resources
            </a>

            <button
              onClick={() => {
                this.props.scrollFunc(5);
              }}
              className="getInTouch"
              type="button"
              style={{
                border: '1px solid',
                borderImage:
                  'linear-gradient(90deg,rgb(175, 122, 39),rgb(231, 192, 90),rgb(175, 122, 39)) !important',
              }}
            >
              <span
                style={{
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  background:
                    'background: linear-gradient(90deg,rgb(175, 122, 39),rgb(231, 192, 90),rgb(175, 122, 39))',
                }}
              >
                get in touch
              </span>
            </button>
          </Content>
          <Mobile>
            <Modal
              style={
                this.props.mobileActive ? { transform: 'translateY(0)' } : {}
              }
              id="menuModal"
            >
              <a
                onClick={() => {
                  this.props.clickFunc();
                  this.props.scrollFunc(1);
                }}
              >
                testimonials
              </a>
              <a
                onClick={() => {
                  this.props.clickFunc();
                  this.props.scrollFunc(2);
                }}
              >
                who we are
              </a>
              <a
                onClick={() => {
                  this.props.clickFunc();
                  this.props.scrollFunc(3);
                }}
              >
                our services
              </a>
              <a
                onClick={() => {
                  this.props.clickFunc();
                  this.props.scrollFunc(4);
                }}
              >
                resources
              </a>
              <button
                onClick={() => {
                  this.props.scrollFunc(5);
                  this.props.clickFunc();
                }}
                className="getInTouch"
                type="button"
                style={{
                  border: '1px solid',
                  borderImage:
                    'linear-gradient(90deg,rgb(175, 122, 39),rgb(231, 192, 90),rgb(175, 122, 39)) !important',
                }}
              >
                <span
                  style={{
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    background:
                      'background: linear-gradient(90deg,rgb(175, 122, 39),rgb(231, 192, 90),rgb(175, 122, 39))',
                  }}
                >
                  get in touch
                </span>
              </button>
            </Modal>
            <button
              className={
                this.props.mobileActive
                  ? 'hamburger hamburger--squeeze is-active'
                  : 'hamburger hamburger--squeeze'
              }
              onClick={() => {
                this.props.clickFunc();
              }}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </Mobile>
        </Root>
      </Headroom>
    );
  }
}
const Modal = styled.div`
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 1;
  top: 0;
  /* left: 100vw; */
  left: 0;
  transform: translateY(-100vh);
  transition: transform 0.2s;
  transition-timing-function: ease;
  height: 100vh;
  width: 100%;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${require('src/assets/images/watermark.svg')});
  background-repeat: no-repeat;
  background-position: -50% bottom;
  /* background-width: */
  background-size: 80% auto;
  a {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: white;
    text-transform: uppercase;
    margin-bottom: 36px;
    cursor: pointer;
  }
  button.getInTouch {
    cursor: pointer;
    margin-top: 60px;
    border: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgb(175, 122, 39),
        rgb(231, 192, 90),
        rgb(175, 122, 39)
      )
      1 !important;
    padding: 7px 10px;
    @media (min-width: ${media.md}) {
      padding: 8px 12px;
    }
    @media (min-width: ${media.lg}) {
      padding: 10px 15px;
    }
    span {
      color: white;
      text-decoration: none;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      background: linear-gradient(
        90deg,
        rgb(175, 122, 39),
        rgb(231, 192, 90),
        rgb(175, 122, 39)
      );
    }
  }

  /* z-index: 0; */
`;
const Mobile = styled.div`
  position: absolute;
  right: 20px;
  display: block;
  overflow: hidden;
  @media (min-width: ${media.sm}) {
    display: none;
  }
  .hamburger {
    padding: 15px 0px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    opacity: 1;
    z-index: 2;
    position: relative;
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background: linear-gradient(
      90deg,
      rgb(175, 122, 39),
      rgb(231, 192, 90),
      rgb(175, 122, 39)
    );
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 35px;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgb(175, 122, 39),
      rgb(231, 192, 90),
      rgb(175, 122, 39)
    );
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;
const Root = styled.div`
  width: 100vw;
  z-index: 9999;
  background-color: #333333 !important;
  height: 75px;
  /* position: fixed; */
  padding: 20px 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);

  @media (min-width: ${media.sm}) {
    padding: 20px 35px;
    height: 100px;
    box-shadow: none;
  }
  @media (min-width: ${media.md}) {
    padding: 20px 50px;
  }
  @media (min-width: ${media.lg}) {
    padding: 12.5px 100px;
  }
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

const Logo = styled.img`
  height: 90%; /* 100 */
  min-height: 48px;
  z-index: 2;
  cursor: pointer;
`;

const Content = styled.div`
  justify-content: center;
  display: none;
  @media (min-width: ${media.sm}) {
    display: flex;
  }
  flex-direction: row;
  /* float: right; */
  align-items: center;
  height: 100%;
  right: 20px;
  @media (min-width: ${media.sm}) {
    right: 35px;
  }
  @media (min-width: ${media.md}) {
    right: 50px;
  }
  @media (min-width: ${media.lg}) {
    right: 100px;
  }
  position: absolute;
  a {
    color: white;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-right: 20px;
    @media (min-width: ${media.md}) {
      right: 30px;
    }
    @media (min-width: ${media.lg}) {
      right: 40px;
    }
    cursor: pointer;
  }

  button.getInTouch {
    cursor: pointer;

    border: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgb(175, 122, 39),
        rgb(231, 192, 90),
        rgb(175, 122, 39)
      )
      1 !important;
    padding: 7px 10px;
    @media (min-width: ${media.md}) {
      padding: 8px 12px;
    }
    @media (min-width: ${media.lg}) {
      padding: 10px 15px;
    }
    span {
      color: white;
      text-decoration: none;
      font-family: Montserrat;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      background: linear-gradient(
        90deg,
        rgb(175, 122, 39),
        rgb(231, 192, 90),
        rgb(175, 122, 39)
      );
    }
  }
`;

import appConfig from 'src/config/app.conf';

// export default {
//   tablet: `${appConfig.mediaQuery.tablet}px`,
//   desktop: `${appConfig.mediaQuery.desktop}px`,
// };

export default {
  xs: '479px',
  sm: '769px',
  md: '991px',
  lg: '1100px',
  xl: '1599px',
  xsup: '480px',
  smup: '768px',
  mdup: '992px',
  lgup: '1200px',
  xlup: '1600px',
};

import React, { Component } from 'react';

import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import RightArrow from 'src/assets/images/rarr.svg';
export default class TeaserBanner extends Component {
  state = {
    scrolled: false,
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll = () => {
    if (!this.state.scrolled) {
      this.setState({ scrolled: true });
    }
  };
  render() {
    return (
      //   <div class="teaserWrapper">
      <Root scrolled={this.state.scrolled}>
        <TeaserBannerDiv>
          <span className="first">accounting</span>
          <span>tax</span>
          <span>advisory services</span>
          <span className="last">outsourcing services</span>
          <a
            onClick={() => {
              this.props.scrollFunc(3);
            }}
            className="learnMore"
          >
            learn more
            {/* <img src={RightArrow} /> */}
          </a>
        </TeaserBannerDiv>
      </Root>
      //   </div>
    );
  }
}

const Root = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  /* margin-top: -50px; */
  transform: translateY(-50%);
  opacity: ${props => (props.scrolled ? 1 : 0)};
  transition: opacity 0.5s;
`;
const TeaserBannerDiv = styled.div`
  position: relative;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #232323;
  margin: 0 20px;
  display: flex;
  align-items: center;
  padding: 32px 0;
  height: 100%;
  /* @media (min-width: ${media.sm}) {
    padding: 0px 0px;
  } */
  flex-direction: column;
  @media(min-width: ${media.sm}){
    padding: 0px 35px;
    height: 100px;
    flex-direction: row;
  }
  @media (min-width: ${media.md}) {
    padding: 0px 35px;
    margin: 0 50px;
  }
  @media (min-width: ${media.lg}) {
    padding: 0px 40px;
    margin: 0 100px;
  }
  

  span {
    font-family: Montserrat;
    opacity: 0.8;
    position: relative;
    font-size: 10px;
    margin-bottom: 16px;
    font-size: 14px;
    @media (min-width: ${media.sm}) {
      margin-bottom: 0%;
      font-size: 11px;
    }
    @media(min-width: ${media.md}){
      font-size: 12px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 13px;
    }
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: white;
    text-transform: uppercase;
    /* margin: 0px 17px; */
    display: flex;
    align-items: center;
  }
  .first {
    margin-left: 0;
  }
  span::after {
    
    @media (min-width: ${media.sm}) {
      content: '';
      background: #c5993e;
      width: 5px;
      height: 5px;
      border-radius: 2px;
      /* margin-left: 8px; */
      margin: 0px 12px;
    }
    @media (min-width: ${media.lg}) {
      margin: 0px 17px;
    }
  }
  .last::after {
    content: '';
    background: none;
    width: 0px;
    height: 0px;
    margin: 0;
  }
  .learnMore {
    
    /* position: absolute; */
    img{
      margin-left: 5px;
    }
    display:flex;
    align-items:center;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    /* line-height: 1.71; */
    padding-bottom: 4px;
    letter-spacing: normal;
    color: #c5993e;
    border-bottom: 1px solid #c5993e;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    /* right: 75px; */
    margin-top: 16px;
    @media (min-width: ${media.sm}) {
      font-size: 11px;
      position: absolute;
      right: 35px;
      margin-top: 0;
    }
    @media (min-width: ${media.md}) {
      font-size: 12px;
      right: 35px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 13px;
      right: 40px;
    }
  }
`;

import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

import InfoBox from './InfoBox';
class ServiceAccordion extends React.Component {
  state = {
    active: 1,
  };
  render() {
    return (
      <Accordion preExpanded={['accounting']}>
        <AccordionItem
          uuid="accounting"
          onClick={() => {
            this.setState({
              active: 1,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 1 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Accounting
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              From cloud-based bookkeeping to assistance in preparing internal
              or external financial statements, our accounting team is
              structured to provide accounting services that are tailored to
              your specific business needs.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          uuid="tax"
          onClick={() => {
            this.setState({
              active: 2,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 2 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Tax
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              If you run a company, we work with you to optimize and integrate
              tax planning opportunities for you and your business. If you are
              self-employed, a non-resident, an investor, or you just need some
              professional advice, our knowledgeable team is happy to help. We
              stay current with the Canada Revenue Agency’s hot topics to guide
              you through the tax compliance and examination process.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          uuid="advisory"
          onClick={() => {
            this.setState({
              active: 3,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 3 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Advisory Services
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              From financial pro forma support to advice on taking on a new
              venture, on a per project basis or for on-going start-up support,
              our industry specialists can help you translate your ideas into
              structure, and develop strategy into actionable items.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          uuid="cfo"
          onClick={() => {
            this.setState({
              active: 4,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 4 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Outsourcing Services
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Designed to help smaller companies and start-ups who are focused
              on growth, our part-time or contract CFO/controller services are
              built around a collaborative approach that ensures we address your
              main concerns and provide clarity to your business’s current
              metrics, and define a clear path forward.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}
export default class Services extends Component {
  render() {
    return (
      <Root id="services">
        <Heading>
          <h3>our services</h3>
          <p>
            A wide range of <br />
            <span>accounting and tax services</span>.
          </p>
        </Heading>
        <ServiceAccordion />
        <InfoWrapper>
          <InfoBox />
        </InfoWrapper>
      </Root>
    );
  }
}

const Root = styled.div`
  /* background-color: #333333; */
  position: relative;
  z-index: 4;
  .accordion {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    display: block;
    @media (min-width: ${media.sm}) {
      display: none;
    }
    background-color: #232323;
    border: none;
    margin-top: 32px;
    .active {
      div {
        color: #c5993e;
        background-color: rgb(28, 28, 28);
        font-weight: 500 !important;
        button span {
          background: #c5993e !important;
        }
        button span:first-of-type,
        button span:last-of-type {
          transform: rotate(90deg) !important;
        }

        button span:last-of-type {
          left: 50% !important;
          right: 50% !important;
        }
      }
    }
    .accordHead {
      div {
        position: relative;
        display: flex;
        align-items: center;

        button {
          position: absolute;
          right: 15px;
          width: 30px;
          height: 30px;
          border: 0;
          font-size: 1.5em;
          /* position: relative; */
        }

        button span {
          position: absolute;
          transition: 0.3s;
          background: white;
          border-radius: 1px;
        }

        button span:first-of-type {
          top: 25%;
          bottom: 25%;
          width: 10%;
          left: 45%;
        }

        button span:last-of-type {
          left: 25%;
          right: 25%;
          height: 10%;
          top: 45%;
        }
      }
    }
    .accordion__item {
      outline: none !important;
    }
    .accordion__button {
      background-color: #232323;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: white;
    }
    .accordion__button::before {
      height: 0px;
      width: 0px;
      margin-right: 0;
      border: none;
    }
    .accordion__button:focus {
      outline: none !important;
    }
    .accordion__panel {
      background-color: rgb(28, 28, 28);
      font-family: Montserrat;
      opacity: 0.8;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: white;
    }
  }
  background-color: white;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  padding: 60px 20px;
  flex-direction: column;
  @media (min-width: ${media.sm}) {
    flex-direction: row;
    padding: 12% 35px;
  }
  @media (min-width: ${media.md}) {
    padding: 10% 50px;
  }
  @media (min-width: ${media.lg}) {
    padding: 8% 100px;
    padding-bottom: 0;
  }
`;
const Heading = styled.div`
  h3 {
    font-family: Montserrat;
    font-size: 12px;
    @media (min-width: ${media.sm}) {
      font-size: 12px;
    }
    @media (min-width: ${media.md}) {
      font-size: 15px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 18px;
    }
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #999999;
    text-transform: uppercase;
  }
  p {
    font-family: Montserrat;
    font-size: 24px;
    margin-top: 16px;
    @media (min-width: ${media.sm}) {
      font-size: 24px;
    }
    @media (min-width: ${media.md}) {
      font-size: 30px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 36px;
    }
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #333333;
    span {
      color: #c5993e;
      font-weight: 500;
    }
  }
`;
const InfoWrapper = styled.div`
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  min-width: 60%;
  height: 100%;
  display: none;
  @media (min-width: ${media.sm}) {
    display: block;
  }
`;

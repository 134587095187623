import React, { Component } from 'react';
import Slider from 'react-slick';

import QuoteBox from './QuoteBox';
import styled from 'styled-components';
import media from 'src/styles/media';
import testimonials from 'src/data/testimonials';
import { mapProps } from 'recompose';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  centerPadding: '20px',
};

class SimpleSlider extends React.Component {
  render() {
    return (
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => {
          return (
            <div key={index}>
              <QuoteBox
                name={testimonial.name}
                desc={testimonial.desc}
                company={testimonial.company}
              />
            </div>
          );
        })}
      </Slider>
    );
  }
}
export default class Testimonials extends Component {
  componentDidMount() {
    if (window.innerWidth < 769) {
      document.querySelector('#testimonials').style.paddingRight = '0';
      document.querySelector('#testimonials').style.paddingLeft = '0';
      document.querySelector('#testimonialTitle').style.marginLeft = '20px';
    }
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    if (window.innerWidth < 769) {
      document.querySelector('#testimonials').style.paddingRight = '0';
      document.querySelector('#testimonials').style.paddingLeft = '0';
      document.querySelector('#testimonialTitle').style.marginLeft = '20px';
    } else if (window.innerWidth < 991) {
      document.querySelector('#testimonials').style.paddingRight = '35px';
      document.querySelector('#testimonials').style.paddingLeft = '35px';
    } else if (window.innerWidth < 1100) {
      document.querySelector('#testimonials').style.paddingRight = '50px';
      document.querySelector('#testimonials').style.paddingLeft = '50px';
    } else {
      document.querySelector('#testimonials').style.paddingRight = '100px';
      document.querySelector('#testimonials').style.paddingLeft = '100px';
    }
  };
  render() {
    return (
      //   <div class="teaserWrapper">
      <Root id="testimonials">
        <div id="testimonialTitle" className="title">
          <Heading>testimonials</Heading>
          <Subtitle className="subtitle">
            <p>
              A <span className="trackRecord">track record </span>
              <br />
              of driving business decisions.
            </p>
          </Subtitle>
        </div>
        <SimpleSlider />
        <QuoteList>
          <QuoteBox
            name="Andrew W."
            desc="Peter has worked with BAM since Day 1. Every year we grow together and I have had great experience working with Peter. Peter has helped so much with my company and personal financial planning. He has helped me weather any type of circumstances and came up with practical advice best suited for my operation. I would recommend Peter to anyone. It’s a bliss knowing there is someone with the level of financial expertise like Peter to be on your side."
            company="BAM Communications"
          />
          <QuoteBox
            name="Aaron P."
            desc="My team and I are very pleased with Peter's services. Peter's service is not just your regular accountant; because of his corporate finance experience, Peter is great to have brainstorming sessions with covering issues from corporate structuring, tax planning, to business venture ideas. Our personal experience with Peter has been very smooth, tackling projects from dealing with the CRA to reorganizing our business affairs. Peter is like having a CFO, but not having one on payroll. I highly recommend Peter to anyone, especially entrepreneurs."
            company="Leaf Cross Collective"
          />
          <QuoteBox
            name="Kelvin Y."
            desc="Peter Wu has done nothing but excellent work for me. He has always gone above and beyond what I require of him and he is passionate about my growth and success as a business and professional. I have recommended him to many of my colleagues and there has been nothing but positive feedback. "
            company="Trifecta Rehab and Kensington Wellness"
            className="last"
          />
        </QuoteList>
      </Root>
      //   </div>
    );
  }
}
const Root = styled.div`
  position: relative;
  z-index: 4;
  .slick-track {
    display: flex;
    .slick-slide {
      display: flex;
      height: inherit;
    }
    .slick-slide > div {
      display: flex;
    }
  }
  .slick-slide,
  .slick-slide * {
    outline: none !important;
  }
  .slick-slider {
    margin-top: 32px;
    display: block;
    @media (min-width: ${media.sm}) {
      display: none;
    }
    /* .slick-active > div > div {
      display: flex !important;
      justify-content: center;
    } */
    .slick-dots {
      bottom: -50px;
      li.slick-active button:before {
        color: #c5993e;
      }
      li button:before {
        font-size: 10px;
      }
    }
  }

  width: auto;
  background-image: url(${require('src/assets/images/slant.svg')});
  background-color: white;
  background-repeat: no-repeat;
  background-position: -100px bottom;

  /* padding: 250px 20px; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 250px;
  padding-bottom: 120px;
  /* margin-top: 200px; */
  @media (min-width: ${media.sm}) {
    margin-top: 0;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 8%;
    padding-bottom: 80px;
  }
  @media (min-width: ${media.md}) {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 8%;
    padding-bottom: 80px;
  }
  @media (min-width: ${media.lg}) {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 8%;
    padding-bottom: 80px;
  }
`;

const Heading = styled.h3`
  font-family: Montserrat;
  font-size: 12px;
  @media (min-width: ${media.sm}) {
    font-size: 12px;
  }
  @media (min-width: ${media.md}) {
    font-size: 15px;
  }
  @media (min-width: ${media.lg}) {
    font-size: 18px;
  }
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  color: #999999;
  text-transform: uppercase;
`;

const Subtitle = styled.div`
  margin-top: 16px;
  @media (min-width: ${media.sm}) {
    margin-top: 12px;
  }
  @media (min-width: ${media.md}) {
    margin-top: 14px;
  }
  @media (min-width: ${media.lg}) {
    margin-top: 16px;
  }
  p {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #333333;
    @media (min-width: ${media.sm}) {
      font-size: 24px;
    }
    @media (min-width: ${media.md}) {
      font-size: 30px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 36px;
    }

    .trackRecord {
      font-weight: 500;
      color: #c5993e;
    }
  }
`;

const QuoteList = styled.div`
  display: none;

  @media (min-width: ${media.sm}) {
    display: flex;
    /* padding-left: 150px; */
    margin-top: 60px;
    flex-direction: row;
    /* justify-content: space-between; */
    /* margin-right: 10px; */
    width: 100%;
    padding-left: 50px;
  }
  @media (min-width: ${media.lg}) {
    padding-left: 100px;
  }
`;

import React, { Component } from 'react';
// import './../assets/css/quoteBox.scss';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import QuoteImg from 'src/assets/images/quote.svg';
export default class QuoteBox extends Component {
  render() {
    return (
      //   <div class="teaserWrapper">
      <Root
        className="quoteBoxComponent"
        style={this.props.className == 'last' ? { marginRight: 0 } : {}}
      >
        <GoldQuote src={QuoteImg} />
        <TextQuoteDiv>
          <p>{this.props.desc}</p>
        </TextQuoteDiv>
        <AuthorQuoteDiv>
          <p>
            <span className="name">{this.props.name}</span>
            <br />
            <span className="company">{this.props.company}</span>
          </p>
        </AuthorQuoteDiv>
      </Root>
      //   </div>
    );
  }
}

const Root = styled.div`
  width: 90%;
  height: 100%;
  background-color: #333333;
  position: relative;
  padding: 24px;
  padding-bottom: 150px;
  margin: 0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  @media (min-width: ${media.sm}) {
    width: ${vwTablet(350)};
    /* min-height: 300px; */
    height: initial;
    padding: 15px;
    margin-right: 20px;
    padding-bottom: 75px;
    min-height: none;
    /* margin-right: 24px; */
  }
  @media (min-width: ${media.md}) {
    width: ${vwTablet(350)};
    /* min-height: 350px; */
    padding: 24px;
    margin-right: 20px;
    padding-bottom: 90px;
  }
  @media (min-width: ${media.lg}) {
    width: ${vwDesktop(400)};
    /* min-height: 360px; */
    padding: 24px;
    margin-right: 20px;
    padding-bottom: 80px;
    /* margin-right: 24px; */
  }
`;
const GoldQuote = styled.img`
  position: relative;
`;
const TextQuoteDiv = styled.div`
  margin-top: 23px;
  padding-bottom: 0;
  @media (min-width: ${media.sm}) {
    margin-top: 10px;
    padding-bottom: 24px;
  }
  @media (min-width: ${media.md}) {
    margin-top: 15px;
  }
  @media (min-width: ${media.lg}) {
    margin-top: 20px;
  }
  p {
    font-family: Montserrat;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: white;
    opacity: 0.8;
    font-size: 15px;
    @media (min-width: ${media.sm}) {
      font-size: 13px;
      /* line-height: 1.69; */
    }
    @media (min-width: ${media.md}) {
      font-size: 14px;
      /* line-height: 1.69; */
    }
    @media (min-width: ${media.lg}) {
      font-size: 15px;
      /* line-height: 1.69; */
    }
  }
`;
const AuthorQuoteDiv = styled.div`
  position: absolute;
  bottom: 32px;
  opacity: 0.8;
  padding-right: 15px;
  @media (min-width: ${media.sm}) {
    bottom: 15px;
  }
  @media (min-width: ${media.md}) {
    bottom: 24px;
  }
  @media (min-width: ${media.lg}) {
    bottom: 24px;
  }
  .name {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: 1.5px;
    color: #c5993e;
    text-transform: uppercase;
    @media (min-width: ${media.sm}) {
      font-size: 13px;
    }
    @media (min-width: ${media.md}) {
      font-size: 14px;
      /* line-height: 1.4; */
    }
    @media (min-width: ${media.lg}) {
      font-size: 15px;
    }
  }
  .company {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: white;
    @media (min-width: ${media.sm}) {
      font-size: 13px;
    }
    @media (min-width: ${media.md}) {
      font-size: 14px;
      /* line-height: 1.4; */
    }
    @media (min-width: ${media.lg}) {
      font-size: 15px;
      /* line-height: 1.69; */
    }
  }
`;

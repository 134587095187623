import React, { Component } from 'react';

import styled from 'styled-components';
import media from 'src/styles/media';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import InfoBox from './InfoBox2';
class ResourceAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1,
    };
  }
  render() {
    return (
      <Accordion preExpanded={['Businesses']}>
        <AccordionItem
          uuid="Businesses"
          onClick={() => {
            this.setState({
              active: 1,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 1 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Businesses
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/corporations/corporation-income-tax-return/when-file-your-corporation-income-tax-return.html"
            >
              Corporate tax filing deadline
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/gst-account/file-a-gst-hst-return.html"
            >
              Filing a GST return
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/e-services/e-services-businesses/payroll-deductions-online-calculator.html"
            >
              CRA payroll deductions calculator
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/e-services/payment-save-time-pay-online.html"
            >
              Making a tax payment
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/compliance/industry-campaign-approach.html"
            >
              CRA’s industry campaign approach
            </a>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          uuid="Individuals"
          onClick={() => {
            this.setState({
              active: 2,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 2 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Individuals
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/important-dates-individuals.html"
            >
              Individual tax filing deadline
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/e-services/payment-save-time-pay-online.html"
            >
              Making a tax payment
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/making-payments-individuals/paying-your-income-tax-instalments.html"
            >
              Paying tax installments
            </a>
            <a
              target="_blank"
              href="https://www.fin.gc.ca/treaties-conventions/in_force--eng.asp"
            >
              Tax treaties between Canada and other countries
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/federal-government-budgets/budget-2016-growing-middle-class/common-reporting-standard.html"
            >
              Common Reporting Standard
            </a>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          uuid="Non-Residents"
          onClick={() => {
            this.setState({
              active: 3,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 3 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Non-Residents
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/individuals-leaving-entering-canada-non-residents/non-residents-canada.html"
            >
              Non-resident tax filing
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2062.html"
            >
              Non-resident sale of Taxable Canadian Property – Clearance
              Certificate
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/nr73.html"
            >
              NR73 Determination of Residency Status (leaving Canada)
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/information-been-moved/determining-your-residency-status.html"
            >
              Determination of (tax) residency status
            </a>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          uuid="Real Estate"
          onClick={() => {
            this.setState({
              active: 4,
            });
          }}
        >
          <AccordionItemHeading
            className={
              this.state.active == 4 ? 'active accordHead' : 'accordHead'
            }
          >
            <AccordionItemButton>
              Real Estate
              <button>
                <span />
                <span />
                <span />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2091ind.html"
            >
              T2091 Designation of a Property as Principal Residence
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/compliance/real-estate-sector/effects-buying-real-estate-sell-for-profit.html"
            >
              Tax effects of buying and selling real estate
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/personal-income/line-127-capital-gains/principal-residence-other-real-estate/changes-use.html"
            >
              Tax effects on change in use (e.g. principal residence to rental
              and vice versa)
            </a>
            <a
              target="_blank"
              href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/gst-hst-home-construction/gst-hst-new-residential-rental-property-rebate.html"
            >
              GST/HST new residential rental property rebate
            </a>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}
export default class Resources extends Component {
  render() {
    return (
      <Root id="resources">
        <Heading>
          <h3>resources</h3>
          <h4>
            Accounting <span>resources</span>.
          </h4>
          <p>
            Follow the links for more information on common tax issues for
            businesses, individuals, non-residents, and the real estate
            industry.
          </p>
        </Heading>
        <ResourceAccordion />
        <InfoWrapper>
          <InfoBox />
        </InfoWrapper>
      </Root>
    );
  }
}

const Root = styled.div`
  background-color: white;
  /* display: flex; */
  position: relative;
  z-index: 4;
  justify-content: space-between;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  @media (min-width: ${media.sm}) {
    padding: 8% 35px;
    flex-direction: row;
  }
  @media (min-width: ${media.md}) {
    padding: 10% 50px;
  }
  @media (min-width: ${media.lg}) {
    padding: 8% 100px;
  }
  .accordion {
    display: block;
    @media (min-width: ${media.sm}) {
      display: none;
    }
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #f7f7f7;
    border: none;
    margin-top: 32px;
    .active {
      div {
        color: #c5993e;
        background-color: rgb(237, 237, 237);
        font-weight: 500 !important;
        button span {
          background: #c5993e !important;
        }
        button span:first-of-type,
        button span:last-of-type {
          transform: rotate(90deg) !important;
        }

        button span:last-of-type {
          left: 50% !important;
          right: 50% !important;
        }
      }
    }
    .accordHead {
      div {
        position: relative;
        display: flex;
        align-items: center;

        button {
          position: absolute;
          right: 15px;
          width: 30px;
          height: 30px;
          border: 0;
          font-size: 1.5em;
          /* position: relative; */
        }

        button span {
          position: absolute;
          transition: 0.3s;
          background: #333333;
          border-radius: 1px;
        }

        button span:first-of-type {
          top: 25%;
          bottom: 25%;
          width: 10%;
          left: 45%;
        }

        button span:last-of-type {
          left: 25%;
          right: 25%;
          height: 10%;
          top: 45%;
        }
      }
    }
    .accordion__item {
      outline: none !important;
    }
    .accordion__button {
      background-color: #f7f7f7;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: #333333;
    }
    .accordion__button::before {
      height: 0px;
      width: 0px;
      margin-right: 0;
      border: none;
    }
    .accordion__button:focus {
      outline: none !important;
    }
    .accordion__panel {
      background-color: rgb(237, 237, 237);
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: white;
      /* display: flex; */
      justify-content: space-between;
      flex-direction: column;
      a {
        position: relative;
        font-family: Montserrat;
        font-size: 14px;
        padding-left: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: normal;
        color: #333333;
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        align-items: center;
        margin-top: 6px;
        opacity: 0.8;
      }
      a::before {
        content: '';
        position: absolute;
        left: 0px;
        width: 5px;
        height: 5px;
        background-color: #b48a33;
        border-radius: 2px;
        margin-right: 16px;
      }
    }
  }
`;

const Heading = styled.div`
  /* margin-top: 120px; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */

  h3 {
    font-family: Montserrat;
    font-size: 12px;
    @media (min-width: ${media.sm}) {
      font-size: 12px;
    }
    @media (min-width: ${media.md}) {
      font-size: 15px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 18px;
    }
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #999999;
    text-transform: uppercase;
  }
  h4 {
    font-family: Montserrat;
    font-size: 24px;
    margin-top: 12px;
    @media (min-width: ${media.sm}) {
      font-size: 24px;
      margin-top: 16px;
    }
    @media (min-width: ${media.md}) {
      font-size: 30px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 36px;
    }
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #333333;
    span {
      font-weight: 500;
      color: #c5993e;
    }
  }
  p {
    padding-right: 20px;
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 12px;
    @media (min-width: ${media.sm}) {
      margin-top: 16px;
      font-size: 14px;
    }
    @media (min-width: ${media.md}) {
      font-size: 15px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 16px;
    }
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #333333;
  }
`;
const InfoWrapper = styled.div`
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  min-width: 60%;
  height: 100%;
  display: none;
  @media (min-width: ${media.sm}) {
    display: block;
  }
`;

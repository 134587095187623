import React, { Component } from 'react';

import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
export default class InfoBox extends Component {
  state = {
    active: 1,
    topOffset: 20,
    prev: 1,
  };
  componentDidMount() {
    // const text2 = document.querySelector('#taxText');
    // const text3 = document.querySelector('#advisoryText');
    // const text4 = document.querySelector('#cfoText');
    // text2.style.transform = 'translateY(300%)';
    // text3.style.transform = 'translateY(300%)';
    // text4.style.transform = 'translateY(300%)';
    // text2.style.animation = 'fadeInDown 1s';
  }
  change = i => {
    this.setState({ active: i });
    var activeP = document.querySelector('#descBox > .active');
    // activeP.style.animation = 'fadeInUp 1s';
    const text1 = document.querySelector('#accountingText');
    const text2 = document.querySelector('#taxText');
    const text3 = document.querySelector('#advisoryText');
    const text4 = document.querySelector('#cfoText');

    if (i == 1) {
      //top
      //bottom 2,3,4

      text2.style.animation = 'none';
      text3.style.animation = 'none';
      text4.style.animation = 'none';

      if (i <= this.state.prev) {
        text1.style.animation = 'slideDown 1s';
      } else {
        text1.style.animation = 'slideUp 1s';
      }
    } else if (i == 2) {
      //top 1
      //bottom 3,4
      text1.style.animation = 'none';
      text3.style.animation = 'none';
      text4.style.animation = 'none';
      if (i <= this.state.prev) {
        text2.style.animation = 'slideDown 1s';
      } else {
        text2.style.animation = 'slideUp 1s';
      }
    } else if (i == 3) {
      //top 1, 2
      //bottom 4
      text1.style.animation = 'none';
      text2.style.animation = 'none';
      text4.style.animation = 'none';
      if (i <= this.state.prev) {
        text3.style.animation = 'slideDown 1s';
      } else {
        text3.style.animation = 'slideUp 1s';
      }
    } else if (i == 4) {
      //top 1, 2, 3
      //bottom 0
      text1.style.animation = 'none';
      text2.style.animation = 'none';
      text3.style.animation = 'none';
      if (i <= this.state.prev) {
        text4.style.animation = 'slideDown 1s';
      } else {
        text4.style.animation = 'slideUp 1s';
      }
    }
    this.setState({ prev: i });
  };
  render() {
    return (
      //   <div class="teaserWrapper">
      <Root>
        <Topic>
          <div
            className="slider"
            style={{ top: this.state.topOffset + (this.state.active - 1) * 60 }}
          />
          <a
            onClick={() => {
              this.change(1);
            }}
            className={this.state.active == 1 ? 'active' : ''}
          >
            Accounting
          </a>
          <a
            onClick={() => {
              this.change(2);
            }}
            className={this.state.active == 2 ? 'active' : ''}
          >
            Tax
          </a>
          <a
            onClick={() => {
              this.change(3);
            }}
            className={this.state.active == 3 ? 'active' : ''}
          >
            Advisory Services
          </a>
          <a
            onClick={() => {
              this.change(4);
            }}
            className={this.state.active == 4 ? 'active' : ''}
          >
            Outsourcing Services
          </a>
        </Topic>
        <Border />
        <Description id="descBox">
          <p
            id="accountingText"
            className={this.state.active == 1 ? 'active' : ''}
          >
            From cloud-based bookkeeping to assistance in preparing internal or
            external financial statements, our accounting team is structured to
            provide accounting services that are tailored to your specific
            business needs.
          </p>
          <p id="taxText" className={this.state.active == 2 ? 'active' : ''}>
            If you run a company, we work with you to optimize and integrate tax
            planning opportunities for you and your business. If you are
            self-employed, a non-resident, an investor, or you just need some
            professional advice, our knowledgeable team is happy to help. We
            stay current with the Canada Revenue Agency’s hot topics to guide
            you through the tax compliance and examination process.
          </p>
          <p
            id="advisoryText"
            className={this.state.active == 3 ? 'active' : ''}
          >
            From financial pro forma support to advice on taking on a new
            venture, on a per project basis or for on-going start-up support,
            our industry specialists can help you translate your ideas into
            structure, and develop strategy into actionable items.
          </p>
          <p id="cfoText" className={this.state.active == 4 ? 'active' : ''}>
            Designed to help smaller companies and start-ups who are focused on
            growth, our part-time or contract CFO/controller services are built
            around a collaborative approach that ensures we address your main
            concerns and provide clarity to your business’s current metrics, and
            define a clear path forward.
          </p>
        </Description>
      </Root>
      //   </div>
    );
  }
}

const Root = styled.div`
  /* width: 70%; */
  height: 400px;
  background-color: #333333;

  display: flex;
`;
const Topic = styled.div`
  min-width: 30%;
  position: relative;
  padding: 20px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  a {
    opacity: 0.8;
    font-family: Montserrat;
    font-size: 16px;
    @media (min-width: ${media.sm}) {
      font-size: 14px;
    }
    @media (min-width: ${media.md}) {
      font-size: 15px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 16px;
    }
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: white;
    height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.5s;
  }
  a.active {
    color: #c5993e;
    transition: 0.5s;
  }
  .slider {
    width: 8px;
    height: 60px;
    background-color: #c5993e;
    position: absolute;
    left: 0;
    transition: 0.5s;
  }
`;
const Border = styled.div`
  width: 24px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.08);
`;
const Description = styled.div`
  padding: 20px;
  width: 100%;
  position: relative;
  @media (min-width: ${media.sm}) {
    padding: 25px;
    padding-top: 30px;
  }
  @media (min-width: ${media.md}) {
    padding: 25px;
    padding-top: 35px;
  }

  p {
    /* display: none; */
    /* position: absolute; */
    font-family: Montserrat;
    font-size: 16px;
    padding-right: 20px;
    opacity: 0.8;
    @media (min-width: ${media.sm}) {
      font-size: 13px;
      padding-right: 25px;
    }
    @media (min-width: ${media.md}) {
      font-size: 14px;
    }

    @media (min-width: ${media.lg}) {
      font-size: 15px;
    }
    font-weight: normal;
    /* width: 86%; */

    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: white;
    /* opacity: 0; */
    /* transform: translateY(100%); */
    position: absolute;
    /* opacity: 0; */
    /* max-height: 0; */
    display: none;
    /* animation: fadeInUp 1s; */
    /* transition: transform 0.5s; */
    /* transition: transform 1s, all 0.5s; */
    /* transition: opacity 0.1s; */
    /* max-height: 0; */
  }
  p.active {
    /* display: block; */
    /* opacity: 1; */
    /* max-height: 100%; */
    /* transform: translateY(0) !important; */
    display: block;
    /* animation: fadeIndown 1s infinite; */
    /* visibility: visible; */
  }
  @keyframes slideUp {
    0% {
      display: none;
      opacity: 0;
      transform: translateY(60px);
    }
    1% {
      display: block;
      opacity: 0;
      transform: translateY(60px);
    }
    100% {
      /* opacity: 1; */
      transform: translateY(0px);
    }
  }
  @keyframes slideDown {
    0% {
      display: none;
      opacity: 0;
      transform: translateY(-60px);
    }
    1% {
      display: block;
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      /* opacity: 1; */
      transform: translateY(0px);
    }
  }
`;

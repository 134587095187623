import React, { Component, StyleSheet } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';

import './assets/css/app.scss';
import Header from 'components/Header';
import DesktopMenu from 'components/DesktopMenu/DesktopMenu';
import Teaser from 'components/Teaser';
import TeaserBanner from 'components/TeaserBanner';
import Testimonials from 'components/Testimonials';
import Who from 'components/Who';
import Services from 'components/Services';
import Resources from 'components/Resources';
import Contact from 'components/Contact';
import Watermark from 'src/assets/images/watermark.svg';
import media from 'src/styles/media';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

var Scroll = require('react-scroll');

var scroll = Scroll.animateScroll;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileActive: false,
    };
  }
  offset = el => {
    var rect = el.getBoundingClientRect(),
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
  };
  scrollTo = i => {
    if (i == 0) {
      scroll.scrollTo(0, {
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    } else if (i == 1) {
      if (
        document.querySelector('#testimonials').getBoundingClientRect().top >= 0
      ) {
        //above
        scroll.scrollTo(this.offset(document.querySelector('#testimonials')), {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      } else {
        //below
        if (window.innerWidth < 769) {
          scroll.scrollTo(
            this.offset(document.querySelector('#testimonials')) + 125,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        } else {
          scroll.scrollTo(
            this.offset(document.querySelector('#testimonials')) - 100,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        }
      }
    } else if (i == 2) {
      if (document.querySelector('#who').getBoundingClientRect().top >= 0) {
        //above
        scroll.scrollTo(this.offset(document.querySelector('#who')), {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      } else {
        if (window.innerWidth < 769) {
          scroll.scrollTo(this.offset(document.querySelector('#who')) - 75, {
            duration: 1500,
            delay: 100,
            smooth: true,
          });
        } else {
          scroll.scrollTo(this.offset(document.querySelector('#who')) - 100, {
            duration: 1500,
            delay: 100,
            smooth: true,
          });
        }
      }
    } else if (i == 3) {
      if (
        document.querySelector('#services').getBoundingClientRect().top >= 0
      ) {
        //above
        scroll.scrollTo(this.offset(document.querySelector('#services')), {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      } else {
        if (window.innerWidth < 769) {
          scroll.scrollTo(
            this.offset(document.querySelector('#services')) - 75,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        } else {
          scroll.scrollTo(
            this.offset(document.querySelector('#services')) - 100,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        }
      }
    } else if (i == 4) {
      if (
        document.querySelector('#resources').getBoundingClientRect().top >= 0
      ) {
        //above
        scroll.scrollTo(this.offset(document.querySelector('#resources')), {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      } else {
        if (window.innerWidth < 769) {
          scroll.scrollTo(
            this.offset(document.querySelector('#resources')) - 75,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        } else {
          scroll.scrollTo(
            this.offset(document.querySelector('#resources')) - 100,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        }
      }
    } else if (i == 5) {
      if (document.querySelector('#contact').getBoundingClientRect().top >= 0) {
        //above
        scroll.scrollTo(this.offset(document.querySelector('#contact')), {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      } else {
        if (window.innerWidth < 769) {
          scroll.scrollTo(
            this.offset(document.querySelector('#contact')) - 75,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        } else {
          scroll.scrollTo(
            this.offset(document.querySelector('#contact')) - 100,
            {
              duration: 1500,
              delay: 100,
              smooth: true,
            }
          );
        }
      }
    }
  };
  menuClick = () => {
    if (!this.state.mobileActive) {
      disableBodyScroll(this.menuModal);
    } else {
      enableBodyScroll(this.menuModal);
    }
    this.setState({ mobileActive: !this.state.mobileActive });
  };
  componentDidMount() {
    prerenderStyles();
    // this.stopBodyScrolling(true);
    this.menuModal = document.querySelector('#menuModal');
  }

  render() {
    return (
      <Root className="appRoot" id="mainContainer">
        <WatermarkFixed src={Watermark} />
        <DesktopMenu
          mobileActive={this.state.mobileActive}
          clickFunc={this.menuClick}
          scrollFunc={this.scrollTo}
        />
        <Teaser scrollFunc={this.scrollTo} />
        <TeaserBanner scrollFunc={this.scrollTo} />
        <Testimonials />

        <Who scrollFunc={this.scrollTo} />
        <Services />
        <Resources />
        <Contact />
      </Root>
    );
  }
}

const Root = styled.div`
  /* background-image: url(${require('src/assets/images/watermark.svg')});
  background-attachment: fixed; */
  .headroom{
    z-index: 6 !important;
  }
  .headroom-wrapper{
    overflow:hidden;
    transition: transform 200ms ease-in-out;
    /* transform: none !important; */
  }
  .headroom--unfixed {
    position: fixed !important;
    transform: none;
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
  .menuUp .headroom--unpinned{
    transform: none !important;
  }
  .headerFix > .headroom {
    transform: none !important;
  }

`;
const WatermarkFixed = styled.img`
  position: fixed;
  bottom: 0;
  left: -20%;
  pointer-events: none;
  width: 100%;
  z-index: 3;
  @media (min-width: ${media.sm}) {
    left: -10%;
    width: 50%;
  }
  /* height: 100%; */
`;
export default (process.env.NODE_ENV === 'development'
  ? hot(module)(App)
  : App);

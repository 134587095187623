const testimonials = [
  {
    name: 'Andrew W.',
    desc:
      'Peter has worked with BAM since Day 1. Every year we grow together and I have had great experience working with Peter. Peter has helped so much with my company and personal financial planning. He has helped me weather any type of circumstances and came up with practical advice best suited for my operation. I would recommend Peter to anyone. It’s a bliss knowing there is someone with the level of financial expertise like Peter to be on your side.',
    company: 'BAM Communications',
  },
  {
    name: 'Aaron P.',
    desc:
      "My team and I are very pleased with Peter's services. Peter's service is not just your regular accountant; because of his corporate finance experience, Peter is great to have brainstorming sessions with covering issues from corporate structuring, tax planning, to business venture ideas. Our personal experience with Peter has been very smooth, tackling projects from dealing with the CRA to reorganizing our business affairs. Peter is like having a CFO, but not having one on payroll. I highly recommend Peter to anyone, especially entrepreneurs.",
    company: 'Leaf Cross Collective',
  },
  {
    name: 'Kelvin Y.',
    desc:
      'Peter Wu has done nothing but excellent work for me. He has always gone above and beyond what I require of him and he is passionate about my growth and success as a business and professional. I have recommended him to many of my colleagues and there has been nothing but positive feedback. ',
    company: 'Trifecta Rehab and Kensington Wellness',
  },
];

export default testimonials;

import React, { Component } from 'react';

import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import CPALogo from 'src/assets/images/cpa.png';
import Logo from 'src/assets/images/logo.svg';
import LeftArrow from 'src/assets/images/larr.svg';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
export default class Contact extends Component {
  state = {
    submitted: false,
    toaOpen: false,
    name: '',
    email: '',
    phone: '',
    org: '',
    message: '',
  };
  componentDidMount() {
    this.target = document.querySelector('#toaModal');
  }
  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };
  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };
  handlePhoneChange = e => {
    this.setState({ phone: e.target.value });
  };
  handleOrgChange = e => {
    this.setState({ org: e.target.value });
  };
  handleMessageChange = e => {
    this.setState({ message: e.target.value });
  };
  serialize(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    return str.join('&');
  }
  submitForm = e => {
    e.preventDefault();
    var data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    if (this.state.phone) {
      data['phone'] = this.state.phone;
    }
    if (this.state.org) {
      data['org'] = this.state.org;
    }

    //var data = this.serialize(data);


    fetch('https://hook.us1.make.com/ivxuhk2bd3x68lbaxa276u3o7jvsngan', {
      method: 'POST',
      headers: {
        Accept: 'application/form-data',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(function(resp) {
      console.log(resp);
    });
    this.setState({ submitted: true });
    // console.log(123);
  };

  stopBodyScrolling = bool => {
    if (bool === true) {
      disableBodyScroll(this.target);
    } else {
      enableBodyScroll(this.target);
    }
  };

  openToa = () => {
    this.setState({ toaOpen: true });
    // document.body.style.overflow = 'hidden';
    this.stopBodyScrolling(true);
  };
  closeToa = () => {
    this.setState({ toaOpen: false });
    // document.body.style.overflow = 'visible';
    this.stopBodyScrolling(false);
  };
  render() {
    return (
      //   <div class="teaserWrapper">
      <Root id="contact">
        <Heading>
          <h3>contact us</h3>
          <p>
            Your <span>trusted partner</span> <br />
            in accounting and tax services.
          </p>
        </Heading>
        <MainDiv>
          <ThankYou
            style={
              this.state.submitted ? { display: 'flex' } : { display: 'none' }
            }
          >
            <p className="head">Thank you for getting in touch.</p>
            <p className="sub">
              Your message has been submitted. We will get back to you as soon
              as possible.
            </p>
          </ThankYou>
          <FormDiv
            style={
              this.state.submitted ? { display: 'none' } : { display: 'flex' }
            }
            // action="/"
            // method="post"
            onSubmit={this.submitForm}
          >
            <div className="inputGroup">
              <span>First Name *</span>
              <input
                type="text"
                name="fullName"
                className="fullName"
                required="required"
                onChange={this.handleNameChange}
              />
            </div>
            <div className="inputGroup">
              <span>Email Address *</span>
              <input
                type="email"
                name="email"
                className="email"
                required
                onChange={this.handleEmailChange}
              />
            </div>
            <div className="inputGroup">
              <span>Phone Number</span>
              <input
                type="text"
                name="phone"
                className="phone"
                onChange={this.handlePhoneChange}
              />
            </div>
            <div className="inputGroup">
              <span>Your Organization (if any)</span>
              <input
                type="text"
                name="organization"
                className="organization"
                onChange={this.handleOrgChange}
              />
            </div>
            <div className="inputGroup">
              <span>Message *</span>
              <textarea
                type="textarea"
                name="message"
                className="message"
                required
                onChange={this.handleMessageChange}
              />
            </div>
            <span>* Required field</span>
            <SubmitBtn type="submit">
              <span>Submit</span>
            </SubmitBtn>
            {/* <input type="" name="fullName" className="fullName" /> */}
          </FormDiv>
          <DescDiv style={this.state.submitted ? { marginTop: 64 } : {}}>
            <h4>peter wu &amp; associates</h4>
            <div>
              <p>
                Suite 1100 - 1200 W 73rd Ave. <br />
                Vancouver, BC V6P 6G5
              </p>
            </div>
            <div>
              <p>Monday - Friday: 8:30AM - 5:00PM</p>
            </div>
            <div>
              <p>
                Phone: <a href="tel:1-604-675-8430">604-675-8430</a>
                <br />
                Fax: 604-608-3229
                <br />
                Email: <a href="mailto: general@pwcpa.ca">general@pwcpa.ca</a>
              </p>
            </div>
            <img src={CPALogo} />
            <a
              className="toa"
              onClick={() => {
                this.openToa();
              }}
            >
              Terms of Use &amp; Privacy Policy
            </a>
          </DescDiv>
        </MainDiv>
        <TOA
          style={
            this.state.toaOpen
              ? {
                  display: 'block',
                  overflowScrolling: 'touch',
                  WebkitOverflowScrolling: 'touch',
                }
              : { display: 'none' }
          }
          id="toaModal"
        >
          <div className="header">
            <img src={Logo} />
          </div>
          <div className="container">
            <div
              className="goBack"
              onClick={() => {
                this.closeToa();
              }}
            >
              <span>
                <img src={LeftArrow} /> <a>Back to Home</a>
              </span>
            </div>
            <div className="section">
              <h4>Legal Disclaimer</h4>
              <p>
                Peter Wu &amp; Associates does not warrant or make any
                representation as to the quality, content, accuracy or
                completeness of any information on this website, or information
                provided through any other site linked to this website.
                Information provided through this website is not an official
                statement of laws, practice, procedure or services. It is
                intended only to give prospective general information.
              </p>
            </div>
            <div className="section">
              <h4>Terms of Use</h4>
              <p>
                The material, images, and information on this website is
                provided on an 'as is' basis solely for the reader's general
                knowledge. The information is not intended to be comprehensive
                and Peter Wu &amp; Associates therefore assumes no
                responsibility and provides no warranty, either express or
                implied, as to its accuracy, completeness or currency. In no
                event will Peter Wu &amp; Associates be liable for any special,
                incidental, indirect or consequential damages of any kind, or
                any damages whatsoever arising out of or in connection with the
                use or performance of this information, including without
                limitation, those resulting from loss of use, data or profits,
                whether or not advised of the possibility of damage. This site
                may contain links to external organizations. We do not control
                the relevance, timeliness, or accuracy of materials produced by
                other organizations, nor do we endorse their views, products or
                services. Your access and use of this website constitutes your
                agreement to be governed by the contents of the disclaimer set
                forth above.
              </p>
            </div>
            <div className="section">
              <h4>Privacy Policy</h4>
              <p>
                Peter Wu &amp; Associates and each of our affiliates and
                partnerships is committed to maintaining the accuracy,
                confidentiality and security of your personal information. This
                Privacy Policy describes the personal information that may be
                collected from or about you, how we use that information, and to
                whom we disclose that information.
              </p>
            </div>
            <div className="section">
              <h4>Collecting Personal Information</h4>
              <p>
                Peter Wu &amp; Associates collects personal information to
                better serve your needs. In order to provide you with our wide
                variety of information, services, products and promotions, we
                may collect the following information:
                <p>
                  Contact and mailing information such as name, address,
                  telephone numbers, fax numbers and email addresses.
                </p>
                <p>
                  Any other relevant information necessary to provide you with
                  information on our products and services.
                </p>
                <p>
                  We may need to collect other personal information depending on
                  the circumstance, but we will ask you directly and ensure that
                  you agree.
                </p>
              </p>
            </div>
            <div className="section">
              <h4>Personal Information Security</h4>
              <p>
                Like you, Peter Wu &amp; Associates values our personal
                information. Part of valuing your personal information is making
                sure that it is protected and kept confidential. We achieve this
                by:
                <p>Having our employees sign a confidentiality agreement.</p>
                <p>
                  Having up to date computer security such as passwords,
                  encryption and firewalls.
                </p>
                <p>
                  Where third parties are hired to assist us, we have them sign
                  a privacy and confidentiality agreement to ensure that your
                  personal information is protected by them.
                </p>
              </p>
            </div>
            <div className="section">
              <h4>Personal Information Updating</h4>
              <p>
                Peter Wu &amp; Associates wants to ensure that your personal
                information is always correct up to date. If your personal
                information changes or is incorrect, updates can be made by
                contacting your representative with the change and any other
                information you feel is necessary.
                <br />
                <br />
                We will keep your information as long as is necessary to
                continue to meet your needs and any legal requirements we may
                have. However, once your personal information no longer needed,
                we will destroy it.
              </p>
            </div>
            <div className="section">
              <h4>Personal Data</h4>
              <p>
                We only collect Personal Data from you, such as first and last
                name, phone number, e-mail, when you register to receive
                information. We collect data about your activities that does not
                personally or directly identify you when you visit our website,
                or the websites and online services where we display
                advertisements. This information may include the content you
                view, the date and time that you view this content, or your
                location information associated with your IP address. We use the
                information we collect to serve you more relevant advertisements
                (referred to as "Retargeting"). We collect information about
                where you saw the ads we serve you and what ads you clicked on.
                <br />
                <br />
                As you browse www.pwcpa.ca, online ad networks we work with may
                place anonymous cookies on your computer, and use similar
                technologies, in order to understand your interests based on
                your (anonymous) online activities, and thus to tailor more
                relevant ads to you. If you do not wish to receive such tailored
                advertising, you can visit this page to opt out of most
                companies that engage in such advertising. (This will not
                prevent you from seeing ads; the ads simply will not be
                delivered through these targeting methods.)
                <br />
                <br />
                We do not target ads to you based on sensitive personal data,
                such as information related to race or ethnic origin, political
                opinions, religious beliefs or other beliefs of a similar
                nature, trade union membership, physical or mental health or
                condition or sexual life. We may target ads based on data
                provided by Advertisers or partners alone or in combination with
                the data we collect ourselves. Any data used to serve targeted
                advertisements is de-identified and is not used to personally or
                directly identify you. In order to allow us to reach the best
                inventory online, we work with third party advertising companies
                (our "Advertising Partners") to help us recognize you and serve
                relevant advertisements to you when you visit a website or
                online service in their network. We also work with Advertising
                Partners who help us recognize you across different devices in
                order to show you relevant advertisements. Our Advertising
                Partners may collect information about your activities on our
                website, and other websites or online services in their
                networks. We also work with third party companies to assist us
                with website analytics such as evaluating the use and operation
                of our website so that we can continue to enhance the website
                and our services.
                <br />
                <br />
                We and our third-party partners use session cookies and
                persistent cookies to make it easier for you to navigate and
                enhance the experience of our site, and to monitor and evaluate
                our website's operation and use.
                <br />
                <br />
                To modify or delete your personal information, or to opt out of
                future communications, please contact us at:
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Peter Wu &amp; Associates
                </span>
                <br />
                Suite 1100 - 1200 W 73RD Ave.
                <br />
                Vancouver, BC
                <br />
                V6P 6G5
                <br />
                <br />
                T.{' '}
                <a href="tel:1-604-675-8430" style={{ color: '#c5993e' }}>
                  604-675-8430
                </a>
                <br />
                F: 604-608-3229
                <br />
                E.{' '}
                <a href="mailto: general@pwcpa.ca" style={{ color: '#c5993e' }}>
                  general@pwcpa.ca
                </a>
              </p>
            </div>
            <div
              className="goBack goBackBot"
              onClick={() => {
                this.closeToa();
              }}
            >
              <span>
                <img src={LeftArrow} /> <a>Back to Home</a>
              </span>
            </div>
          </div>
        </TOA>
      </Root>
    );
  }
}
const TOA = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  background: #333333;
  z-index: 10;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .header {
    padding: 12px 20px;
    @media (min-width: ${media.sm}) {
      padding: 14px 80px;
    }
    @media (min-width: ${media.md}) {
      padding: 16px 100px;
    }
    @media (min-width: ${media.lg}) {
      padding: 18px 120px;
    }
  }
  .container {
    padding: 12px 40px;
    @media (min-width: ${media.sm}) {
      padding: 50px 100px;
    }
    @media (min-width: ${media.md}) {
      padding: 100px 150px;
    }
    @media (min-width: ${media.lg}) {
      padding: 120px 222px;
    }
    .section {
      margin-top: 40px;
      position: relative;
      h4 {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: 1.5px;
        color: #c5993e;
        text-transform: uppercase;
      }
      p {
        position: relative;
        margin-top: 8px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: white;
        p {
          padding-left: 20px;
        }
        p:before {
          content: '';
          height: 4px;
          width: 4px;
          position: absolute;
          background-color: #b48a33;
          border-radius: 2px;
          left: 5px;
          top: 10px;
        }
      }
    }
    .goBack {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: white;
      margin-bottom: 20px;
      span {
        display: flex;
        align-items: center;
      }
      a {
        margin-left: 5px;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: white;
        text-decoration: underline;
      }
    }
  }
  .goBackBot {
    margin-top: 40px;
    @media (min-width: ${media.sm}) {
      margin-top: 120px;
    }
  }
`;
const ThankYou = styled.div`
  flex-direction: column;
  margin-top: 64px;
  width: 100%;
  @media (min-width: ${media.sm}) {
    width: 50%;
  }
  .head {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c5993e;
  }
  .sub {
    margin-top: 16px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: white;
  }
`;
const Root = styled.div`
  background-color: #333333;
  padding: 60px 20px;
  z-index: 2;
  /* margin-bottom: 100px; */
  @media (min-width: ${media.sm}) {
    /* margin-bottom: 0; */
    padding: 12% 35px;
  }
  @media (min-width: ${media.md}) {
    padding: 10% 50px;
  }
  @media (min-width: ${media.lg}) {
    padding: 8% 100px;
  }
`;

const Heading = styled.div`
  /* margin-top: 120px; */

  h3 {
    font-family: Montserrat;
    font-size: 12px;
    @media (min-width: ${media.sm}) {
      font-size: 12px;
    }
    @media (min-width: ${media.md}) {
      font-size: 15px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 18px;
    }
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #999999;
    text-transform: uppercase;
  }
  p {
    margin-top: 12px;
    font-family: Montserrat;
    font-size: 24px;
    @media (min-width: ${media.sm}) {
      font-size: 24px;
      margin-top: 16px;
    }
    @media (min-width: ${media.md}) {
      font-size: 30px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 36px;
    }
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: white;
    span {
      font-weight: 500;
      color: #c5993e;
    }
  }
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${media.sm}) {
    flex-direction: row;
  }
`;

const FormDiv = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${media.sm}) {
    width: 50%;
  }
  span {
    margin-top: 10px;
    font-family: Montserrat;
    font-size: 14px;
    @media (min-width: ${media.sm}) {
      font-size: 10px;
    }
    @media (min-width: ${media.md}) {
      font-size: 12px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 14px;
    }
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #999999;
  }
  .inputGroup {
    margin-top: 41px;
    @media (min-width: ${media.sm}) {
      margin-top: 31px;
    }
    @media (min-width: ${media.md}) {
      margin-top: 36px;
    }
    @media (min-width: ${media.lg}) {
      margin-top: 41px;
    }
    position: relative;
    span {
      font-family: Montserrat;
      font-size: 14px;
      @media (min-width: ${media.sm}) {
        font-size: 10px;
      }
      @media (min-width: ${media.md}) {
        font-size: 12px;
      }
      @media (min-width: ${media.lg}) {
        font-size: 14px;
      }
      margin-top: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #999999;
      position: absolute;
      top: -35px;
    }
    input[type='text'],
    input[type='email'] {
      border: solid 1px #999999;
      width: 100%;
      height: 44px;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
      padding: 8px 16px;
      font-family: Montserrat;
      font-size: 16px;
      @media (min-width: ${media.sm}) {
        font-size: 14px;
      }
      @media (min-width: ${media.md}) {
        font-size: 15px;
      }
      @media (min-width: ${media.lg}) {
        font-size: 16px;
      }
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: white;
    }
    input:focus,
    textarea:focus {
      outline: 1px solid white;
    }

    textarea {
      border: solid 1px #999999;
      width: 100%;
      height: 100px;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
      padding: 8px 16px;
      font-family: Montserrat;
      font-size: 16px;
      @media (min-width: ${media.sm}) {
        font-size: 14px;
      }
      @media (min-width: ${media.md}) {
        font-size: 15px;
      }
      @media (min-width: ${media.lg}) {
        font-size: 16px;
      }
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      /* line-height: 1.69; */
      letter-spacing: normal;
      color: white;
      /* outline: 1px solid white;/ */
    }
  }
`;
const SubmitBtn = styled.button`
  cursor: pointer;
  margin-top: 24px;
  padding: 13px 40px;
  /* width: 50%; */
  width: 191px;
  z-index: 3;
  @media (min-width: ${media.sm}) {
    /* width: 33%; */
    width: 191px;
  }
  border: none;
  /* border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgb(175, 122, 39),
      rgb(231, 192, 90),
      rgb(175, 122, 39)
    )
    1; */
  background: #c5993e;
  color: white;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 14px;
  @media (min-width: ${media.sm}) {
    font-size: 12px;
  }
  @media (min-width: ${media.md}) {
    font-size: 13px;
  }
  @media (min-width: ${media.lg}) {
    font-size: 14px;
  }
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  span {
    /* background: linear-gradient(
      90deg,
      rgb(175, 122, 39),
      rgb(231, 192, 90),
      rgb(175, 122, 39)
    ); */
    color: white;
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
`;
const DescDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
  position: relative;
  width: 100%;
  padding-bottom: 60px;
  @media (min-width: ${media.sm}) {
    width: 40%;
    margin-top: -8px;
    padding-bottom: 60px;
  }
  @media (min-width: ${media.md}) {
    width: 35%;
  }
  @media (min-width: ${media.lg}) {
    width: 30%;
  }
  /* align-items: center; */
  h4 {
    font-family: Montserrat;
    font-size: 20px;
    @media (min-width: ${media.sm}) {
      font-size: 18px;
    }
    @media (min-width: ${media.md}) {
      font-size: 19px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 20px;
    }
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    text-transform: uppercase;
  }
  div {
    margin-top: 24px;

    position: relative;
    p {
      opacity: 0.8;
      font-family: Montserrat;
      font-size: 14px;
      @media (min-width: ${media.sm}) {
        font-size: 12px;
      }
      @media (min-width: ${media.md}) {
        font-size: 13px;
      }
      @media (min-width: ${media.lg}) {
        font-size: 14px;
      }
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: white;
      span {
        color: #c5993e;
      }
      a {
        color: #c5993e;
      }
    }
  }
  img {
    margin-top: 24px;
    width: initial;
    max-width: 160px;
    @media (min-width: ${media.sm}) {
      max-width: none;
      width: 50%;
    }
  }
  .toa {
    cursor: pointer;
    font-family: Montserrat;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    @media (min-width: ${media.sm}) {
      font-size: 10px;
    }
    @media (min-width: ${media.md}) {
      font-size: 11px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 12px;
    }
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
  }
`;

import React, { Component } from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import ClapImg from 'src/assets/images/clap.jpg';
import ShakeImg from 'src/assets/images/shake.jpg';
export default class Who extends Component {
  render() {
    return (
      //   <div class="teaserWrapper">
      <Root id="who">
        <Heading>
          <h3>who we are</h3>
          <p>
            We are entrepreneurs <span>helping entrepreneurs</span>.
          </p>
        </Heading>
        <ImageDesc>
          <img src={ClapImg} />
          <div>
            <p className="left">
              Peter Wu &amp; Associates is an experienced accounting team that
              can provide you with customized accounting, tax, and financial
              advisory services.
            </p>
          </div>
        </ImageDesc>
        <ImageDesc className="desktop">
          <div>
            <p className="right">
              Whether you are a start-up that requires advice on a per project
              basis, or an established business that needs tax and accounting
              support, our team can bring clarity to your financial landscape.
            </p>
          </div>
          <img src={ShakeImg} />
        </ImageDesc>
        <ImageDesc className="mobile">
          <img src={ShakeImg} />
          <div>
            <p>
              Whether you are a start-up that requires advice on a per project
              basis, or an established business that needs tax and accounting
              support, our team can bring clarity to your financial landscape.
            </p>
          </div>
        </ImageDesc>
        <GetInTouch
          onClick={() => {
            this.props.scrollFunc(5);
          }}
          className="getInTouch"
          type="button"
          style={{
            border: '1px solid',
            borderImage:
              'linear-gradient(90deg,rgb(175, 122, 39),rgb(231, 192, 90),rgb(175, 122, 39)) !important',
          }}
        >
          <span
            style={{
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              background:
                'background: linear-gradient(90deg,rgb(175, 122, 39),rgb(231, 192, 90),rgb(175, 122, 39))',
            }}
          >
            get in touch
          </span>
        </GetInTouch>
      </Root>
    );
  }
}

const Root = styled.div`
  background-color: #333333;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* position: relative; */
  z-index: 2;
  padding: 60px 20px;
  /* background-image: url(${require('src/assets/images/watermark.svg')});
  background-repeat: no-repeat;
  background-position: -20% bottom;
  background-size: 80% auto; */
  @media (min-width: ${media.sm}) {
    padding: 12% 35px;
    /* background-position: -20% bottom;
    background-size: 90% auto; */
    align-items: center;
  }
  @media (min-width: ${media.md}) {
    /* background-position: -50% bottom; */
    /* background-width: */
    /* background-size: 80% auto; */
    padding: 10% 50px;
  }
  @media (min-width: ${media.lg}) {
    padding: 8% 100px;
  }
  .desktop {
    display: none;
    @media (min-width: ${media.sm}) {
      display: flex;
    }
  }
  .mobile {
    display: flex;
    @media (min-width: ${media.sm}) {
      display: none;
    }
  }
`;

const Heading = styled.div`
  /* margin-top: 120px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: stretch;
  flex-direction: column;
  @media (min-width: ${media.sm}) {
    align-items: center;
  }
  h3 {
    font-family: Montserrat;
    font-size: 12px;
    @media (min-width: ${media.sm}) {
      font-size: 12px;
    }
    @media (min-width: ${media.md}) {
      font-size: 15px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 18px;
    }
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #999999;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  p {
    font-family: Montserrat;
    font-size: 24px;
    @media (min-width: ${media.sm}) {
      font-size: 24px;
    }
    @media (min-width: ${media.md}) {
      font-size: 30px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 36px;
    }
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: white;
    span {
      font-weight: 500;
      color: #c5993e;
    }
  }
`;
const ImageDesc = styled.div`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  flex-direction: column;
  .left,
  .right {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: ${media.sm}) {
    flex-direction: row;
    .left {
      padding-right: 50px;
    }
    .right {
      padding-left: 50px;
    }
  }

  img {
    /* box-shadow: 0 10px 15px 0 #232323;
     */
    width: 100%;
    @media (min-width: ${media.sm}) {
      width: 50%;
    }
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 1);
    background-color: #232323;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    @media (min-width: ${media.sm}) {
      width: 40%;
      margin-top: 0;
    }
    p {
      opacity: 0.8;
      font-family: Montserrat;
      font-size: 16px;
      margin-top: 16px;
      @media (min-width: ${media.sm}) {
        font-size: 16px;
        margin-top: 0;
      }
      @media (min-width: ${media.md}) {
        font-size: 17px;
      }
      @media (min-width: ${media.lg}) {
        font-size: 18px;
      }
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.78;
      letter-spacing: normal;
      color: white;
    }
  }
`;

const GetInTouch = styled.button`
  cursor: pointer;
  margin-top: 60px;
  padding: 13px 40px;
  border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgb(175, 122, 39),
      rgb(231, 192, 90),
      rgb(175, 122, 39)
    )
    1 !important;
  width: fit-content;

  span {
    color: white;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 14px;
    @media (min-width: ${media.sm}) {
      font-size: 12px;
    }
    @media (min-width: ${media.md}) {
      font-size: 13px;
    }
    @media (min-width: ${media.lg}) {
      font-size: 14px;
    }
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    background: linear-gradient(
      90deg,
      rgb(175, 122, 39),
      rgb(231, 192, 90),
      rgb(175, 122, 39)
    );
  }
`;
